<template>
  <!-- 提数字通证限制 -->
  <div class="page">
    <h4 style="color: #409eff">提数字通证限制</h4>
    <!-- form表单 -->
    <el-form :model="ruleForm" label-width="140px" ref="ruleForm" :rules="rules">
      <el-form-item label="*限制人手机号" prop="timeAllocation">
        <el-input v-model="ruleForm.timeAllocation" size="" placeholder="请输入限制人手机号"></el-input>
      </el-form-item>
      <el-form-item label="限制分类" prop="digitalCertificate">
        <el-select v-model="ruleForm.digitalCertificate" placeholder="提数字通证限制">
          <el-option label="测试" value="测试"></el-option>
          <el-option label="乾锦通" value="乾锦通"></el-option>
          <el-option label="伏羲宝" value="伏羲宝"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="*限制数字通证" prop="tongZheng">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0"></div>
        <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button type="info" @click="getBack">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
const cityOptions = ['QJT', 'FXB']
export default {
  data() {
    return {
      ruleForm: {
        digitalCertificate: '', // 分类
        timeAllocation: '', // 手机号
        tongZheng: '' // 数字通证多选框
      },
      //   限制数字通证多选框
      checkAll: false,
      checkedCities: ['QJT', 'QJT'],
      cities: cityOptions,
      isIndeterminate: true,
      //   验证规则
      rules: {
        digitalCertificate: [{ required: true, message: '请选择分类', trigger: 'change' }],
        timeAllocation: [{ required: true, message: '请输入手机号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    //   限制数字通证多选框
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length
    },
    // 点击提交
    submitForm(formName) {
      console.log(this.form)
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 返回
    getBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding: 30px;
  width: 400px;
}
</style>
